import { Box, LinearProgress } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { PATH_AUTH, PATH_DASHBOARD, PATH_USER } from "src/routes/paths";
import { setSession } from "src/utils/jwt";
import useQueryParams from "src/hooks/useQueryParams";
import axiosInstance from "src/utils/axios";
import { useNavigate } from "react-router";
import useAuth from "src/hooks/useAuth";

const VerifiedUser = () => {
  const { getUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { queryObject } = useQueryParams();
  const { token } = queryObject || {};
  useEffect(() => {
    const makeRequest = async () => {
      try {
        const reqData = new FormData();
        reqData.append("token", token);
        const { status, data } = await axiosInstance.get(
          `user-verify?token=${token}`
        );

        const { package_status } = data;
        if (status === 200) {
          localStorage.setItem("isAdmin", false);
          localStorage.setItem("isSubAdmin", false);
          localStorage.setItem("package_status", Boolean(package_status));
          setSession(token);
          enqueueSnackbar("verified", { variant: "success" });
          window.location = "/user/dashboard";
          getUser();
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar(error.message, { variant: "error" });
      }
    };

    makeRequest();
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress />
    </Box>
  );
};

export default VerifiedUser;
