import { Typography } from "@mui/material";
import React from "react";

const ConfirmPopUP = ({ message }) => {
  return (
    <div className="registerAlert">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="green">
        <path d="M9 16.17l-3.59-3.58L4 14l5 5 10-10-1.41-1.42L9 16.17z" />
      </svg>
      <Typography sx={{ p: 2 }}>{message}</Typography>
    </div>
  );
};

export default ConfirmPopUP;
