import { ethers } from "ethers";
import { twoPlaceRound } from "src/utils/round";

export const convertToWei = (amount) =>
  ethers.utils.parseUnits(amount.toString());

const calculateTotalAmount = (list = {}) =>
  twoPlaceRound(list.reduce((acc, amount) => acc + parseFloat(amount || 0), 0));

export const convertAmountToWei = (list = {}) =>
  list.map((amount) => ethers.utils.parseUnits(amount.toString()));

const calculateTotalAmountInWei = (list = []) => {
  const totalAmount = calculateTotalAmount(list);
  if (totalAmount === 0) throw new Error("Amount Cannot be zero");
  return ethers.utils.parseUnits(totalAmount.toString(), 18);
};

export default calculateTotalAmountInWei;
