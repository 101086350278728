import { Suspense, useState } from "react";
import { Outlet } from "react-router-dom";
import ImpersonationBanner from "src/components/impersonationBanner";
import Ternary from "src/components/ternary";
import useCollapseDrawer from "src/hooks/useCollapseDrawer";
import {
  DashboardHeader,
  HorizontalWrapper,
  MainStyle,
  NavbarVertical,
} from "../shared";
import NavbarUser from "src/pages/mainpage/NavbarUser";
import UserVerticalNavbar from "../shared/navbar/nav-bar-user";
import MainStyleUser from "../shared/mainStyleUser";

const Horizontal = ({ navConfig, isUser }) => {
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const [open, setOpen] = useState(false);

  return (
    <HorizontalWrapper isUser={isUser}>
      <Ternary
        when={isUser}
        then={<NavbarUser isUser={isUser} />}
        otherwise={
          <DashboardHeader
            isCollapse={isCollapse}
            onOpenSidebar={() => setOpen(true)}
          />
        }
      />

      <Ternary
        when={isUser}
        then={
          <UserVerticalNavbar
            config={navConfig}
            isOpenSidebar={open}
            isCollapse={isCollapse}
          />
        }
        otherwise={
          <NavbarVertical
            isOpenSidebar={open}
            onCloseSidebar={() => setOpen(false)}
            config={navConfig}
          />
        }
      />
      {isUser ? (
        <MainStyleUser collapseClick={collapseClick}>
          <Suspense fallback={<>loading...</>}>
            <ImpersonationBanner />
            <Outlet />
          </Suspense>
        </MainStyleUser>
      ) : (
        <MainStyle collapseClick={collapseClick}>
          <Suspense fallback={<>loading...</>}>
            <ImpersonationBanner />
            <Outlet />
          </Suspense>
        </MainStyle>
      )}
    </HorizontalWrapper>
  );
};

export default Horizontal;
