import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import LandingBackground from "src/components/background/backbround-image";

const HorizontalWrapper = ({ children, isUser }) => {
  const { palette } = useTheme();
  if (isUser) {
    return <LandingBackground>{children}</LandingBackground>;
  }
  return (
    <Box
      sx={{
        background: palette.background.main,
        display: { lg: "flex" },
        minHeight: { lg: 1 },
      }}
    >
      {children}
    </Box>
  );
};

export default HorizontalWrapper;
