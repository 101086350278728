import Ternary from "src/components/ternary";
import useSettings from "src/hooks/useSettings";
import Vertical from "./components/vertical";

import Horizontal from "./components/horizontal";

const Layout = ({ isUser = false }) => {
  const { themeLayout } = useSettings();
  const verticalLayout = themeLayout === "vertical";
  const config = JSON.parse(localStorage.getItem("menu") || "[]");

  return <Horizontal navConfig={config} isUser={isUser} />;
};
export default Layout;
