import ParticleSection from "./particles";

const { Box } = require("@mui/material");

const LandingBackground = ({ children }) => {
  return (
    <Box className="landingBg" sx={{display:"flex", flexWrap:"wrap"}}>
      <ParticleSection />
      {children}
    </Box>
  );
};

export default LandingBackground;
