import { ADMIN_WALLET, NETWORK } from "src/config";
import { convertToWei } from "src/pages/admin/financial/payout/components/request/utils/calculate-total-amount-in-wei";
import getChain from "src/pages/admin/financial/payout/components/request/utils/get-chain";
import getSigner from "src/pages/admin/financial/payout/components/request/utils/get-signer";
import getTokenContract from "src/pages/admin/financial/payout/components/request/utils/get-token-contract";

const handleCryptoPayments = async (amount) => {
  const chain = getChain()[NETWORK];
  const signer = await getSigner(chain);
  const tokenContract = getTokenContract(signer);
  const tranferAmountInWei = convertToWei(amount);
  const transfer = await (
    await tokenContract.transfer(ADMIN_WALLET, tranferAmountInWei)
  ).wait();
  return transfer.transactionHash;
};

export default handleCryptoPayments;
