import React, { useState, useRef } from "react";
import { Box } from "@mui/material";
import "./nav.css";
import DNA from "../icons/dashboard.svg";
import Bounty from "../icons/lottery.svg";
import Orbit from "../icons/orbit.svg";
import FundTransfer from "../icons/fund-transfer.svg";
import Referral from "../icons/referral.svg";
import Report from "../icons/report.svg";
import Shopping from "../icons/shopping.svg";
import Withdrawal from "../icons/withdrawal.svg";
import Support from "../icons/support.svg";
import Surrender from "../icons/surrender.svg";
import NoSurrender from "../icons/notSurrender.svg";

import { PATH_USER } from "src/routes/paths";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

const UserVerticalNavbar = ({ config, isCollapse }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef(null); // Reference to the menu container
  const { user } = useAuth();

  const clusterCount = user.cluster_id;
  // const clusters = Array.from(
  //   { length: clusterCount },
  //   (_, index) => index + 1
  // );
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  window.onscroll = function () {
    addFixedClass();
  };

  function addFixedClass() {
    const verticalMenu = document.querySelector(".vertical-menu");
    if (window.scrollY > 130) {
      verticalMenu.classList.add("stickyNav");
    } else {
      verticalMenu.classList.remove("stickyNav");
    }
  }
  const location = useLocation();
  const isDisabled =
    user.userStatus === "inactive" || user.userStatus === "capitulate";
  return (
    <Box>
      <div className="menu-toggle" onClick={toggleMenu}>
        {menuOpen ? (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
            viewBox="0 0 24 24"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-grid"
            viewBox="0 0 24 24"
          >
            <rect x="3" y="3" width="7" height="7"></rect>
            <rect x="14" y="3" width="7" height="7"></rect>
            <rect x="14" y="14" width="7" height="7"></rect>
            <rect x="3" y="14" width="7" height="7"></rect>
          </svg>
        )}
      </div>

      <Box className="NavMainContainer">
        <nav
          className={`vertical-menu ${menuOpen ? "open" : ""}`}
          ref={menuRef}
        >
          <ul>
            <li>
              <NavLink
                to="/user/dashboard"
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <img src={DNA} alt="DNA" />
                <span>DNA</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH_USER.referral}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <img src={Referral} alt="Referral" />
                <span>Referral</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH_USER.bountyProgram.root}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <img src={Bounty} alt="Bounty Program" />
                <span>Bounty Program</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${PATH_USER.genealogy.orbitView}?cluster=${clusterCount}`}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <img src={Orbit} alt="Orbit View" />
                <span>My Orbit</span>
              </NavLink>
            </li>

            {/* <li id="ReportSub" className="relative">
              <a>
                <img src={Orbit} alt="Orbit" />
                <span>Orbit View</span>
              </a>
              <span className="afterinfo"></span>
              <ul className="SubMenuItemBox">
                {clusters.map((cluster) => (
                  <li key={cluster}>
                    <NavLink
                      to={`${PATH_USER.genealogy.orbitView}?cluster=${cluster}`}
                    >
                      <span>Cluster {cluster}</span>
                    </NavLink>
                  </li>
                ))}
              </ul>
            </li> */}

            <li>
              <NavLink
                to={PATH_USER.financial.fundsTransfer}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <img src={FundTransfer} alt="Fund Transfer" />
                <span>Fund Transfer</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH_USER.userReport.root}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <img src={Report} alt="Report" />
                <span>REPORT</span>
              </NavLink>
            </li>
            {/* <li id="ReportSub" className="relative">
              <a>
                <img src={Report} alt="Report" />
                <span>Report</span>
              </a>
              <span className="afterinfo"></span>
              <ul className="SubMenuItemBox">
                <li>
                  <NavLink to={PATH_USER.incomeReport.root}>
                    <span>Revenue Report</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to={PATH_USER.withdrawalReport.root}>
                    <span>Withdrawal Report</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="">
                    <span>Bounty Report</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink to="">
                    <span>Auction Report</span>
                  </NavLink>
                </li>
              </ul>
            </li> */}
            <li>
              <NavLink
                to={PATH_USER.financial.payout}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <img src={Withdrawal} alt="Withdrawal" />
                <span>Withdrawal</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={PATH_USER.helpCenter.createTicket.view}
                className={({ isActive }) => (isActive ? "active" : "")}
              >
                <img src={Support} alt="Support" />
                <span>Support</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
                to={isDisabled ? "#" : PATH_USER.capitulate.root}
                className={({ isActive }) =>
                  isDisabled ? "disabled-link" : isActive ? "active" : ""
                }
                onClick={(e) => {
                  if (isDisabled) {
                    e.preventDefault(); // Prevent navigation if disabled
                  }
                }}
              >
                <img src={isDisabled ? NoSurrender : Surrender} alt="Support" />
                <span>Capitulate</span>
              </NavLink>
            </li>
          </ul>
        </nav>
      </Box>
    </Box>
  );
};

export default UserVerticalNavbar;
