import { Box, Button, Container, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { SentIcon } from "src/assets";
import Page from "src/components/Page";
import Translate from "src/components/translate";
import LogoOnlyLayout from "src/layouts/LogoOnlyLayout";
import { PATH_AUTH } from "src/routes/paths";
import { ResetPasswordForm } from "src/sections/auth/reset-password";
import axiosInstance from "src/utils/axios";
import ParticleSection from "src/components/background/particles";
import "./reset.css";

const RootStyle = styled("div")(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

const SendEmailSection = ({ onSent, validationError }) => {
  return (
    <Box className="resetBox" sx={{ color: "#fff" }}>
      <Typography sx={{ fontSize: "18px", color: "#fff" }} paragraph>
        <Translate>register.forgot_yur</Translate>
      </Typography>
      <Typography sx={{ color: "#a5afc3", mb: 5 }}>
        <Translate>register.pls_enter</Translate>
      </Typography>

      <ResetPasswordForm onSent={onSent} validationError={validationError} />

      <Button
        fullWidth
        size="large"
        component={RouterLink}
        to={"/"}
        name="reset-back"
        sx={{
          mt: 1,
          color: "#958262",
          boxShadow: "none",
          "&:hover": {
            color: "#74694b", // Define the hover background color
            boxShadow: "none",
            backgroundColor: "transparent", // Customize hover shadow if needed
          },
        }}
      >
        <Translate>register.back</Translate>
      </Button>
    </Box>
  );
};

const AfterSend = ({ mail }) => (
  <Box sx={{ textAlign: "center" }}>
    <SentIcon sx={{ mb: 5, mx: "auto", height: 160 }} />

    <Typography variant="h3" sx={{ color: "white" }} gutterBottom>
      <Translate>register.request</Translate>
    </Typography>
    <Typography sx={{ color: "gray" }}>
      <Translate>register.we_have</Translate> &nbsp;
      <strong>{mail}</strong>
      <br />
      <Translate>register.please</Translate>
    </Typography>

    <Button
      size="large"
      variant="contained"
      component={RouterLink}
      to={"/"}
      sx={{ mt: 5 }}
    >
      <Translate>register.back</Translate>
    </Button>
  </Box>
);

export default function ResetPassword() {
  const [sent, setSent] = useState(false);
  const [sentMailId, setSentMailId] = useState("");
  const [validationError, setValidationError] = useState();

  const { t } = useTranslation();
  const sendResetRequest = async (email) => {
    const formData = new FormData();
    formData.append("username", email);
    setValidationError();
    try {
      const res = await axiosInstance.post("/api/forgot-password", formData, {
        headers: {
          "Content-Type":
            "multipart/form-data; boundary=<calculated when request is sent>",
        },
      });
      if (res.status === 200) {
        setSentMailId(email);
        setSent(true);
      }
    } catch (err) {
      setValidationError(err?.errors?.username);
    }
  };

  return (
    <AuthWraper>
      {sent ? (
        <AfterSend mail={sentMailId} />
      ) : (
        <SendEmailSection
          onSent={sendResetRequest}
          validationError={validationError}
        />
      )}
    </AuthWraper>
  );
}

export const AuthWraper = ({ children }) => {
  return (
    <Box className="landingBg">
      <ParticleSection />
      <Page title="Reset Passkey" sx={{ height: 1 }}>
        <RootStyle>
          <LogoOnlyLayout />
          <Box className="resetMain" sx={{ maxWidth: 480, mx: "auto" }}>
            {children}
          </Box>
        </RootStyle>
      </Page>
    </Box>
  );
};
