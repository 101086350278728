import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import Iconify from "src/components/Iconify";
import Transition from "src/utils/dialog-animation";

const Disclosure = ({ open, onClose }) => {
  return (
    <div>
      {" "}
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiPaper-root": {
            background:
              "linear-gradient(90deg, rgba(11, 25, 62, 1) 0%, rgba(39, 21, 37, 1) 80%, rgba(56, 19, 21, 1) 100%)",
            color: "#fff", // Optional: Ensure text is readable
            borderRadius: "12px", // Add rounded corners
          },
        }}
      >
        <DialogTitle
          id="category"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Disclosure</Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <Iconify icon="ic:baseline-close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: 800, overflow: "auto" }}>
          <div className="addrexxBox">
            <p>
              Product name, logo, brands, and other trademarks featured or
              referred to within xorbit.ai are the property of their respective
              trademark holders. This site may be compensated through third
              party advertisers. The offers that may appear on xorbit.ai website
              from companies from which xorbit.ai may receive compensation. This
              compensation may influence the selection, appearance, and order of
              appearance of the offers listed on the website. However, this
              compensation also facilitates the provision by xorbit.ai of
              certain services to you at no charge. The website does not include
              all Crypto services companies or all of their available product
              and service offerings.
            </p>
            <br />
            <p>Cookies Policy</p>
            <br />
            <p>#yourdataisyours</p>
            <br />
            <p>
              At Crypto, we respect your concerns about privacy and value the
              relationship that we have with you.
            </p>
            <br />
            <p>
              Like many companies, we use technology on our website to collect
              information that helps us enhance your experience and our products
              and services. The cookies that we use at Crypto allow our website
              to work and help us to understand what information and advertising
              is most useful to visitors.
            </p>
            <br />
            <p>
              Please take a moment to familiarize yourself with our cookie
              practices and let us know if you have any questions by sending us
              an e-mail or submitting a request through the “Contact Us” form on
              our websites.
            </p>
            <br />
            <p style={{ fontWeight: "bold", color: "blue" }}>
              Who is collecting it?
            </p>
            <br />
            <p>
              Any personal data provided to or collected by Crypto via cookies
              and other tracking technologies is controlled by both Crypto PLC
              and Crypto N.V., which are the joint parent companies of the
              Crypto Group (all Crypto Group companies, together the data
              controllers).
            </p>
            <br />
            <p>
              By using our websites, you are consenting to our use of cookies in
              accordance with this Cookie Notice and our Privacy Notice.
            </p>
            <br />
            <p>
              This Cookie Notice applies to any websites, apps, branded pages on
              third-party platforms (such as Facebook or YouTube), and
              applications accessed or used through such websites or third-party
              platforms (hereinafter, “our websites”) which are operated by or
              on behalf of Crypto.
            </p>
            <br />
            <p>
              By using our websites, you are consenting to our use of cookies in
              accordance with this Cookie Notice and our Privacy Notice. If you
              do not agree to our use of cookies in this way, you should set
              your browser settings accordingly, disable the cookies that we use
              or not use our websites at all. If you disable the cookies we use,
              this may impact your user experience while on the websites.
            </p>
            <br />
            <p>
              The section below summarizes the different types of cookies we use
              on our websites, together with their respective purpose and
              provides you with the ability to manage these cookies.
            </p>
            <br />
            <p>
              Crypto will only collect, use or disclose your personal data where
              it is fair and lawful to do so. For a more detailed understanding
              of how we use personal data collected by Crypto cookies, please
              refer to our Privacy Notice.
            </p>
            <br />
            <p style={{ fontWeight: "bold", color: "blue" }}>
              What does cookie mean?
            </p>
            <br />
            <p>
              Cookies, pixel tags and similar technologies (collectively
              ‘cookies’) are files containing small amounts of information which
              are downloaded to any internet enabled device – such as your
              computer, smart phone or tablet – when you visit a website.
            </p>
            <br />
            <p>
              Cookies, pixel tags and similar technologies (collectively
              ‘cookies’) are files containing small amounts of information which
              are downloaded to any internet enabled device – such as your
              computer, smart phone or tablet – when you visit a website.
              Cookies are then sent back to the originating website on each
              subsequent visit, or to another website that recognizes that
              cookie. Cookies do lots of different and useful jobs, such as
              remembering your preferences, generally improving your online
              experience, and helping us to offer you the best product and
              services. There are many types of cookies. They all work in the
              same way, but have minor differences. For a detailed list of
              cookies used on our websites, please refer to the below relevant
              section.
            </p>
            <br />
            <p style={{ fontWeight: "bold", color: "blue" }}>
              What purpose do we use cookies for?{" "}
            </p>
            <br />
            <p>
              We use cookies to make Crypto websites easier to use, to deliver a
              personalized experience on our websites, and to better tailor our
              products, services and websites to your interests and needs.
              Cookies are used to help speed up your future activities and your
              experience on Crypto websites.
            </p>
            <br />
            <p>
              We use cookies to make Crypto websites easier to use, to deliver a
              personalized experience on our websites, and to better tailor our
              products, services and websites to your interests and needs.
              Cookies are used to help speed up your future activities and your
              experience on Crypto websites.
            </p>
            <br />
            <p>
              The cookies you consent to, are also used to collect your personal
              data which we then profile into audiences so that we can deliver
              targeted advertising tailored to your interests and limit the
              number of times you see an advertisement. For more detailed
              information about the profiling activities Crypto undertakes with
              your personal data for advertising, please see our Privacy Notice.
            </p>
            <br />
            <p>
              We also insert cookies in emails and newsletters to improve our
              content and advertising.
            </p>
            <br />
            <p>
              Lastly, we use cookies to compile anonymous, aggregated statistics
              that allow us to understand how people use our websites and to
              help us improve their structure and content and also help us
              measure the effectiveness of advertising campaigns on Crypto and
              non-Crypto websites.
            </p>
            <br />
            <p>
              More detailed information about the types of cookies we use and
              for what purposes, can be found in the below relevant section.
            </p>
            <br />
            <p style={{ fontWeight: "bold", color: "blue" }}>
              How can I control or delete cookies?{" "}
            </p>
            <br />
            <p>There are many ways to manage your cookies:</p>
            <br />
            <p>
              <ul className="addrexxBoxNew">
                <li>You can refuse your consent;</li>
                <li>
                  You can disable Crypto or third-party cookies by use of your
                  browser settings (see how here); or
                </li>

                <li>
                  You can use our cookie management tool to disable Crypto or
                  third-party cookies (see how here). There are many ways to
                  manage your cookies:
                </li>
                <li>You can refuse your consent;</li>
                <li>
                  You can disable Crypto or third-party cookies by use of your
                  browser settings (see how here); or
                </li>
                <li>
                  You can use our cookie management tool to disable Crypto or
                  third-party cookies (see how here).
                </li>
              </ul>
            </p>
            <br />
            <p style={{ fontWeight: "bold", color: "blue" }}>
              Control via your browser settings{" "}
            </p>
            <br />
            <p>
              Most internet browsers are initially set up to automatically
              accept cookies. If you do not want our websites to store cookies
              on your device, you can change your browser settings so that you
              receive a warning before certain cookies are stored. You can also
              adjust your settings so that your browser refuses most of our
              cookies or only certain cookies from third parties. You can also
              withdraw your consent to cookies by deleting the cookies that have
              already been stored.
            </p>
            <br />
            <p>
              If you disable the cookies that we use, this may impact your
              experience while on the Crypto website, for example you may not be
              able to visit certain areas of a website or you may not receive
              personalized information when you visit a website
            </p>
            <br />
            <p>
              If you use different devices to view and access the Crypto website
              (e.g., your computer, smart phone, tablet) you will need to ensure
              that each browser on each device is adjusted to suit your cookie
              preferences
            </p>
            <br />
            <p>
              The procedures for changing your settings and cookies differ from
              browser to browser. If necessary, use the help function on your
              browser or click on one of the links below to go directly to the
              user manual for your browser.
            </p>
            <br />
            <ul className="addrexxBoxNew">
              <li>
                <a
                  target="_blank"
                  href="https://support.microsoft.com/en-us/topic/how-to-delete-cookie-files-in-internet-explorer-bca9446f-d873-78de-77ba-d42645fa52fc"
                  style={{ color: "pink", fontSize: "12px" }}
                >
                  Internet Explorer
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectslug=delete-cookies-remove-info-websites-stored&redirectlocale=en-US"
                  style={{ color: "pink", fontSize: "12px" }}
                >
                  Mozilla Firefox
                </a>
              </li>{" "}
              <li>
                <a
                  target="_blank"
                  href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=en"
                  style={{ color: "pink", fontSize: "12px" }}
                >
                  Google Chrome{" "}
                </a>
              </li>{" "}
              <li>
                <a
                  target="_blank"
                  href="https://support.apple.com/kb/ph21411?locale=en_US"
                  style={{ color: "pink", fontSize: "12px" }}
                >
                  Safari{" "}
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  href="https://help.opera.com/en/latest/web-preferences/"
                  style={{ color: "pink", fontSize: "12px" }}
                >
                  Opera
                </a>
              </li>
            </ul>
            <br />
            <p>
              There are also software products available that can manage cookies
              for you.
            </p>
            <br />
            <p>
              You can also use{" "}
              <a
                target="_blank"
                href="https://www.ghostery.com/"
                style={{ color: "pink", fontSize: "12px" }}
              >
                www.ghostery.com
              </a>{" "}
              to evaluate the use of cookies used on our websites. To find out
              more about cookies, including how to see what cookies have been
              set and how to manage and delete them, visit
              <a
                target="_blank"
                href="https://allaboutcookies.org/"
                style={{ color: "pink", fontSize: "12px" }}
              >
                www.allaboutcookies.org.
              </a>
            </p>
            <br />
            <p style={{ fontWeight: "bold", color: "blue" }}>
              Control via our cookie management tool{" "}
            </p>
            <br />
            <p>
              You can disable Crypto or third-party cookies by use of our cookie
              consent tool which can be found here.
            </p>
            <br />
            <p>
              Switching off tracking cookies, for example, ensures that Crypto
              will no longer track your online behaviour. However please note
              that opting-out from tracking cookies does not necessarily mean
              that you will receive less Crypto advertising. It just means that
              the advertising you receive will not be tailored to your
              interests.
            </p>
            <br />

            <p style={{ fontWeight: "bold", color: "blue" }}>
              What cookies do we use?{" "}
            </p>
            <br />
            <p>
              The cookies used on Crypto websites may be generally categorized
              as follow:
            </p>
            <br />
            <p>
              <ul className="addrexxBoxNew">
                <li>
                  Necessary Cookies. These cookies do not identify you as an
                  individual.
                </li>
                <li>
                  Performance Cookies. These cookies do not identify you as an
                  individual.
                </li>
                <li>
                  Functionality Cookies. The information these cookies collect
                  may include personal data that you have disclosed.
                </li>
                <li>
                  Targeting or Advertising Cookies. Most types of these cookies
                  track consumers via their Device ID or IP address therefore
                  they may collect personal data.
                </li>
                <li>
                  Third-Party Cookies. Based on the type of cookies used by the
                  relevant third-party, the information these cookies collect
                  may include personal data. The cookies used on Crypto websites
                  may be generally categorized as follow:
                </li>
                <li>
                  Necessary Cookies. These cookies are essential to make Crypto
                  websites work correctly, they enable you to move around our
                  websites and use our features. Without these cookies, services
                  like shopping baskets cannot be provided. Examples include
                  remembering previous actions (e.g. entered text) when
                  navigating back to a page in the same session.
                  <br />
                  <ul>
                    <li>
                      Do these cookies collect personal data/identify me? These
                      cookies do not identify you as an individual. If you do
                      not accept these cookies, it may affect the performance of
                      the website, or parts of it.
                    </li>
                  </ul>
                </li>
                <li>
                  Performance Cookies. These cookies collect information about
                  how you use our websites, for instance which pages you go to
                  most often, the time spent on our websites, and any issues
                  encountered, such as error messages. These cookies are also
                  used to let affiliates know if you came to one of our websites
                  from an affiliate and if your visit resulted in the use or
                  purchase of a product or service from us, including details of
                  the product or service purchased. This helps us improve the
                  performance of our websites.
                  <ul>
                    <li>
                      Do these cookies collect personal data/identify me? These
                      cookies do not identify you as an individual. All
                      information these cookies collect is aggregated and
                      therefore anonymous. It is only used to improve how a
                      website works.
                    </li>
                  </ul>
                </li>
                <li>
                  Functionality Cookies. These cookies allow our websites to
                  remember the choices you make (such as your user’s name,
                  language or the region you are in) to provide a more
                  personalized online experience. These cookies may also be used
                  to remember changes you have made to text size, fonts and
                  other parts of web pages that you can customize. Similarly,
                  they may be used to keep track of what featured products or
                  videos have been viewed to avoid repetition, and to enable you
                  to play games and engage with social tools, such as blogs,
                  chat rooms and forums.
                  <ul>
                    <li>
                      Do these cookies collect personal data/identify me? The
                      information these cookies collect may include personal
                      data that you have disclosed. If you do not accept these
                      cookies, it may affect the performance and functionality
                      of the website and may restrict access to content on the
                      website.
                    </li>
                  </ul>
                </li>
                <li>
                  Targeting or Advertising Cookies. These cookies are used to
                  deliver content that is more relevant to you and your
                  interests. They are also used to deliver targeted advertising
                  or limit the number of times you see an advertisement as well
                  as help measure the effectiveness of the advertising campaigns
                  on Crypto and non-Crypto websites. They remember that you have
                  visited one of our websites and this information is shared
                  with other parties, including advertisers and our agencies.
                  These cookies may also be linked to site functionality
                  provided by third-parties.
                  <ul>
                    <li>
                      Do these cookies collect personal data/identify me? Most
                      types of these cookies track consumers via their Device ID
                      or IP address therefore they may collect personal data.
                    </li>
                  </ul>
                </li>
                <li>
                  Third-Party Cookies. We use a number of partners that may also
                  set cookies on your device on our behalf when you visit our
                  websites to allow them to deliver tailored Crypto advertising
                  within their domains, for example Facebook and Google Double
                  Click. We endeavour to identify these cookies before they are
                  used so that you can decide whether you wish to accept them or
                  not. We also use a number of partners to provide digital
                  experiences and functionalities on our websites. For example,
                  while browsing Crypto websites you may be served cookies from
                  third- parties who provide some of its features on our
                  websites (e.g., a YouTube video), although you have withdrawn
                  or declined your consent to our cookies. This happens because
                  you have directly given your consent to the use of their
                  cookies. In such cases, you should directly withdraw your
                  consent on the relevant third-party website.
                  <ul>
                    <li>
                      Do these cookies collect personal data/identify me? Based
                      on the type of cookies used by the relevant third-party,
                      the information these cookies collect may include personal
                      data.
                    </li>
                  </ul>
                </li>
              </ul>
            </p>
            <br />

            <p style={{ fontWeight: "bold", color: "blue" }}>
              Duration of the cookie we use{" "}
            </p>
            <br />
            <p>
              In terms of duration, we may use two different types of cookies on
              our websites:
            </p>
            <br />

            <p>
              <ul className="addrexxBoxNew">
                <li>
                  Session Cookies. These cookies are temporary cookies that
                  remain on your device until you leave our websites; or
                </li>
                <li>
                  Persistent Cookies. These cookies remain on your device for
                  much longer or until you manually delete them (how long the
                  cookie remains on your device will depend on the duration or
                  “lifetime” of the specific cookie, as well as your browser
                  settings, as stated below).
                </li>
              </ul>
            </p>
          </div>
        </DialogContent>
      </Dialog>{" "}
    </div>
  );
};

export default Disclosure;
