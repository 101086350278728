import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import Iconify from "src/components/Iconify";
import Transition from "src/utils/dialog-animation";

const TermsOfService = ({ handleOpenPrivacy, open, onClose }) => {
  return (
    <div>
      {" "}
      <Dialog
        TransitionComponent={Transition}
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={onClose}
        sx={{
          "& .MuiPaper-root": {
            background:
              "linear-gradient(90deg, rgba(11, 25, 62, 1) 0%, rgba(39, 21, 37, 1) 80%, rgba(56, 19, 21, 1) 100%)",
            color: "#fff", // Optional: Ensure text is readable
            borderRadius: "12px", // Add rounded corners
          },
        }}
      >
        <DialogTitle
          id="category"
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">Terms Of Service</Typography>
          <IconButton aria-label="close" onClick={onClose}>
            <Iconify icon="ic:baseline-close" />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ height: 800, overflow: "auto" }}>
          <div className="addrexxBox">
            {" "}
            <p>Last updated April 10, 2024</p>
            <br />
            <p>
              Thanks for using the xorbit.ai services (including its website and
              web-based applications, and any other tools, products, or services
              provided by xorbit.ai that link to or reference these Terms)
              (collectively, the “Services”). The Services are provided by
              XORBIT.AI (“XORBIT.AI, “we,” “our,” or “us”), located at Los
              Verde, 101 Avenida Norte #517, San Salvador, El Salvador
            </p>
            <br />
            <p>
              By using our Services, you are agreeing to these Terms of Service
              (“Terms”). Please read them carefully. Our Services are very
              diverse, so sometimes additional terms or product requirements
              (including age requirements) may apply. If additional terms or
              conditions are available with or applicable to the relevant
              Services, then those additional terms become part of your
              agreement with us if you use those Services. By accessing or using
              the Services, you intend and agree to be legally bound by these
              Terms. You may wish to print or save a local copy of the Terms for
              your records.
            </p>
            <br />
            <p>
              YOU ACKNOWLEDGE AND AGREE THAT THESE TERMS OF SERVICE LIMIT OUR
              LIABILITY AND THAT YOU ARE RELEASING US FROM VARIOUS CLAIMS IN
              SECTION 8 BELOW. THESE TERMS ALSO CONTAIN A BINDING ARBITRATION
              PROVISION IN SECTION 13 THAT AFFECT YOUR RIGHTS UNDER THESE TERMS
              WITH RESPECT TO THE SERVICES.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>1. Using our Services</p>
            <br />
            <p>
              PLEASE BE ADVISED THAT WE ARE NOT A Crypto INSTITUTION OR A
              PROVIDER OF Crypto PRODUCTS OR SERVICES. We do not provide the
              Crypto products or services described on xorbit.ai. Instead,
              xorbit.ai acts as a venue to allow third-party providers of Crypto
              products and services, such as Crypto institutions, credit card
              providers, mortgage brokers, insurance brokers, lenders, and other
              third parties who are customers of xorbit.ai (such as marketing
              partners and finders working on behalf of third-party providers of
              Crypto products and services) (each, a “Service Provider”) to
              offer Crypto products and services to you and other potential
              consumers (each, a “Consumer”) in a variety of formats. We are not
              involved in the actual transaction between any Consumer and any
              Service Provider, even though we may, through xorbit.ai, and with
              each Consumer’s authorization, collect certain information
              submitted through xorbit.ai about a Consumer (collectively, a
              “Lead”), and sell such Lead to a Service Provider. As a result,
              the quality, safety, or legality of the Crypto products and
              services offered by a Service Provider, the ability of a Service
              Provider to offer, sell, or deliver such products or services, or
              the ability of Consumers to pay for the products or services
              offered are solely the responsibility of each Service Provider and
              Consumer, and not XORBIT.AI. We are an independent contractor for
              all business purposes. We are not responsible in any way for the
              conduct of any Service Provider or Consumer. Although XORBIT.AI,
              Inc, Inc is compensated by Service Providers when we provide Leads
              or display advertisements or other content promoting their
              products or services, we do not endorse or recommend any Service
              Provider, or any products or services offered by any Service
              Provider. We do not provide Crypto, tax, legal, or any other
              advice. We do not guarantee that any of the Service Providers to
              whom we forward a Consumer’s information will contact such
              Consumer or agree to provide the Consumer with the desired or
              requested Crypto products or services. If you are a Consumer and
              you would like personal Crypto advice, please consult with a
              Crypto adviser or other qualified professional.
            </p>
            <br />
            <p>
              In order to provide certain Crypto products and services, or to
              help to verify your identity, Service Providers may obtain,
              verify, and record information that identifies each person who
              inquiries about Crypto products or services from them. For
              example, Service Providers may ask for your name, Social Security
              Number, driver’s license number, address, date of birth, or other
              important information that will allow proper identification of
              you, both internally and with the assistance of unaffiliated third
              parties or credit bureaus through a credit check(s). By submitting
              such information on xorbit.ai, you authorize us to provide such
              information to Service Providers in order to help you complete
              your request (including providing you with information about other
              products or services in which you have expressed an interest), and
              to enable the Service Providers to provide products and services
              to you. The Crypto products and services provided or advertised on
              and through xorbit.ai may not be available in all states, and the
              availability of such products and services on and through
              xorbit.ai may change from time to time and without notice. The
              data and other information you may provide through xorbit.ai is
              not, and is not treated as, an application for, or a request to be
              pre-approved, pre-qualified, or any similar concept for, any
              Crypto products or services. Likewise, XORBIT.AI, Inc does not
              guarantee that the terms, rates, or pricing for the products or
              services made available by the Service Providers through xorbit.ai
              are the best terms or lowest pricing or rates available in the
              market.
            </p>{" "}
            <br />
            <p>
              Further, you must also follow any policies made available to you
              within the Services.
            </p>
            <br />
            <p>
              Don’t misuse our Services. For example, don’t interfere with our
              Services, try to access them using a method other than the
              interface and the instructions that we provide, or extensively or
              automatically copy any content from the Services (in other words,
              no scraping). You may use our Services only for your personal
              non-commercial use, and as permitted by law, including applicable
              export and re-export control laws and regulations. We may suspend
              or stop providing our Services to you if you do not comply with
              our terms or policies, if we are investigating suspected
              misconduct, or for any other reason.
            </p>
            <br />
            <p>
              Using our Services does not give you ownership of any intellectual
              property rights in our Services or the content you access through
              them (“Content”). You may not use Content, except as permitted in
              these Terms, by its owner, or as otherwise permitted by law. These
              Terms do not grant you the right to use any branding or logos used
              in our Services,
            </p>
            <br />
            <p>
              including the xorbit.ai and/or XORBIT.AI, Inc names and logos.
              Don’t remove, obscure, or alter any legal notices displayed in or
              along with our Services.
            </p>
            <br />
            <p>
              Our Services display some Content that is not our own. For
              example, Content belonging to our Service Providers, advertisers,
              affiliates, other third parties, you, or other users
              (collectively, “Third Party Content”). We are not responsible for,
              and you waive all of our liability with respect to, Third Party
              Content. Third Party Content is the sole responsibility of the
              individual or entity that makes it available to you via the
              Services. We may review Third Party Content to determine whether
              it is illegal or violates our policies, and we may remove or
              refuse to display Third Party Content that we believe violates our
              policies or the law. But we do not generally review content
              beforehand, and we are not obligated to do so.
            </p>
            <br />
            <p>
              In connection with your use of the Services, we may send you
              service announcements, administrative messages, and other
              information. You may opt out of our marketing emails by clicking
              on the “unsubscribe” link in marketing emails. Please be aware
              that there may be a brief period before we are able to process
              your opt-out.
            </p>
            <br />
            <p>
              xorbit.ai is available on mobile devices, which may cause you to
              incur data charges with your wireless provider. Please be aware
              that we have no control over these charges, and if you do not wish
              to be charged, you should stop using xorbit.ai on your mobile
              device.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>2. Your xorbit.ai Account</p>
            <br />
            <p>
              You may need an account in order to use the Services. If you
              create your own account, you agree that all registration
              information you give us will be accurate and current. You will
              timely notify us of any changes to any of the foregoing
              information. You are responsible for controlling access to any
              PCs, mobile devices, or other end points that you allow to store
              your Services password, or on which you enable a “Remember Me” or
              similar functionality (“Activated Device”). Accordingly, you agree
              that you will be solely responsible for all activities that occur
              under your Services accounts, including the activities of any
              individual with whom you share your Services account or an
              Activated Device, as discussed further below.
            </p>
            <br />
            <p>
              To protect your account, keep your password confidential. You are
              responsible for the activity that happens on or through your
              account. If you learn of any unauthorized use of your password,
              please contact us at support@xorbit.ai
            </p>
            <br />
            <p>
              User verification on the Internet is difficult, and we cannot and
              do not confirm each user’s purported identity. We encourage you to
              use appropriate caution with anyone with whom you may be doing
              business via xorbit.ai or the Internet, generally. When you give
              someone your online ID and online password, you are authorizing
              that person to access and use your account, and you are
              responsible for any and all transactions that person performs
              while using your account, even those transactions that are
              fraudulent or that you did not intend or want performed. EACH USER
              ACKNOWLEDGES AND AGREES THAT: (i) NEITHER XORBIT.AI, INC NOR ANY
              OF ITS AFFILIATES WILL HAVE ANY LIABILITY TO ANY USER FOR ANY
              UNAUTHORIZED TRANSACTION MADE USING ANY USER’S ONLINE ID AND
              PASSWORD THAT OCCURS BEFORE SUCH USER HAS NOTIFIED US OF POSSIBLE
              UNAUTHORIZED USE OF SUCH ONLINE ID AND PASSWORD AND WE HAVE HAD A
              REASONABLE OPPORTUNITY TO ACT ON THAT NOTICE; AND (ii) THE
              UNAUTHORIZED USE OF YOUR ONLINE ID AND PASSWORD COULD CAUSE YOU TO
              INCUR LIABILITY TO BOTH XORBIT.AI, INC, SERVICE PROVIDERS, AND
              OTHER USERS. Further, we may suspend or cancel your account or
              your access to xorbit.ai at any time with or without notice if we
              suspect that your account and/or password is being used in an
              unauthorized or fraudulent manner.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>3. Privacy and Feedback</p>
            <br />
            <p>
              Our{" "}
              <a onClick={handleOpenPrivacy} style={{ color: "green" }}>
                privacy policy
              </a>{" "}
              explains how we treat your personal information and protect your
              privacy when you use our Services. By using our Services, you
              agree that we can collect, use, and share data from you as
              described in our privacy policy. We are not responsible for any
              information or Content that you share with others via your use of
              the Services. You assume all privacy, security, and other risks
              associated with providing any information, including personally
              identifiable information, to other users of the Service.
            </p>
            <br />
            <p>
              If you submit feedback or suggestions about our Services, you
              agree that we may use your feedback or suggestions without
              obligation to you.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>4. Content You Submit or Share</p>
            <br />
            <p>
              You may submit and share text and other content to or through the
              Services (such as reviews you write and comments you post on
              xorbit.ai) (“Your Content”), and in doing so you must follow these
              Terms and the rules and policies referenced in these Terms.
            </p>
            <br />
            <p>
              When you upload, submit, or otherwise share Your Content to or
              through our Services, you give us (and those we work with) a
              royalty-free, worldwide license to use, host, store, reproduce,
              modify, create derivative works (such as those resulting from
              translations, adaptations or other changes we make so that Your
              Content works better with our Services), communicate, publish,
              publicly perform, publicly display and distribute Your Content.
              The rights you grant in this license are for the limited purpose
              of operating, promoting, and improving our Services, and to
              develop new ones. This license continues even if you stop using
              our Services. Make sure you have the necessary rights to grant us
              this license for any content that you submit to our Services.
            </p>
            <br />
            <p>
              We may publicly display Your Content on the Services (including
              through third-party software applications that enable you to post
              comments or reviews on xorbit.ai). You may request that we delete
              any of Your Content that you submit to the Services by sending us
              an email at support@xorbit.ai. To the extent within our control,
              we’ll remove Your Content from public display on xorbit.ai and
              mark it for future deletion if permitted by applicable law;
              however, it may persist in backup or residual copies for a
              reasonable period of time (but will not be available to other
              users through the Services). For purposes of clarification, once
              you submit or share Your Content with others via the Services
              (e.g., other users or third parties), we no longer have control
              over those portions of Your Content and will not be able to delete
              it or prevent them from using it.
            </p>
            <br />
            <p>You agree that you will not use the Services to:</p>
            <br />
            <ul className="addrexxBoxNew">
              <li style={{ fontSize: "12px" }}>
                Violate law or a third-party’s rights;
              </li>
              <li style={{ fontSize: "12px" }}>
                Submit excessive or unsolicited commercial messages or spam any
                users;
              </li>
              <li style={{ fontSize: "12px" }}>
                Submit malicious content or viruses;
              </li>
              <li style={{ fontSize: "12px" }}>
                Solicit other people’s login information, credit card numbers,
                or other sensitive information;
              </li>
              <li style={{ fontSize: "12px" }}>
                Harass or bully other users; or
              </li>
              <li style={{ fontSize: "12px" }}>
                Post content that is hate speech, threatening or pornographic,
                that incites violence or that contains nudity or graphic or
                gratuitous violence.
              </li>
            </ul>
            <br />
            <p style={{ fontWeight: "bold" }}>
              5. INTELLECTUAL PROPERTY PROTECTION
            </p>
            <br />
            <p>
              As we ask others to respect our intellectual property rights, we
              respect the intellectual property rights of others, and require
              our users and customers to do so. If you are a copyright owner or
              its agent and believe that any content residing on or accessible
              through the Services infringes upon your copyrights, you may
              submit a notification under the Digital Millennium Copyright Act
              (“DMCA”) by providing our Copyright Agent (the “Designated Agent”)
              with the following information in writing (see 17 U.S.C §
              512(c)(3) for further detail):
            </p>
            <br />
            <ul className="addrexxBoxNew">
              <li style={{ fontSize: "12px" }}>
                Identification of the work or material being infringed.
              </li>
              <li style={{ fontSize: "12px" }}>
                Identification of the material that is claimed to be infringing,
                including its location, with sufficient detail so that we are
                capable of finding it and verifying its existence.
              </li>

              <li style={{ fontSize: "12px" }}>
                Contact information for the notifying party (the “Notifying
                Party”), including name, address, telephone number, and email
                address.
              </li>

              <li style={{ fontSize: "12px" }}>
                A statement that the Notifying Party has a good faith belief
                that the material is not authorized by the copyright owner, its
                agent or law
              </li>
              <li style={{ fontSize: "12px" }}>
                § A statement made under penalty of perjury that the information
                provided in the notice is accurate and that the Notifying Party
                is authorized to make the complaint on behalf of the copyright
                owner.
              </li>
              <li style={{ fontSize: "12px" }}>
                A physical or electronic signature of a person authorized to act
                on behalf of the owner of the copyright that has been allegedly
                infringed.
              </li>
            </ul>
            <br />
            <p>
              Please also note that the information provided in a notice of
              copyright infringement may be forwarded to the user who posted the
              allegedly infringing content. After removing material in response
              to a valid DMCA notice, we will notify the user responsible for
              the allegedly infringing material that we have removed or disabled
              access to the material. We will terminate, under appropriate
              circumstances, users who are repeat copyright infringes, and we
              reserve the right, in our sole discretion, to terminate any user
              for actual or apparent copyright infringement.
            </p>
            <br />
            <p>
              If you believe you are the wrongful subject of a DMCA
              notification, you may file a counter-notification with us by
              providing the following information to the Designated Agent at the
              address below:
            </p>
            <br />
            <ul className="addrexxBoxNew">
              <li style={{ fontSize: "12px" }}>
                The specific URLs of material that we have removed or to which
                we have disabled access
              </li>
              <li style={{ fontSize: "12px" }}>
                Your name, address, telephone number, and email address.
              </li>{" "}
              <li style={{ fontSize: "12px" }}>
                A statement that you consent to the jurisdiction of El Salvador.
                District Court for the Central District of El Salvador, and that
                you will accept service of process from the person who provided
                the original DMCA notification or an agent of such person.
              </li>
              <li style={{ fontSize: "12px" }}>
                The following statement: “I swear, under penalty of perjury,
                that I have a good faith belief that the material was removed or
                disabled as a result of a mistake or misidentification of the
                material to be removed or disabled.”
              </li>
              <li style={{ fontSize: "12px" }}>Your signature.</li>
            </ul>
            <br />
            <p>
              Upon receipt of a valid counter-notification, we will forward it
              to Notifying Party who submitted the original DMCA notification.
              The original Notifying Party (or the copyright holder he or she
              represents) will then have ten (10) days to notify us that he or
              she has filed legal action relating to the allegedly infringing
              material. If we do not receive any such notification within ten
              (10) days, we may restore the material to the Services.
            </p>
            <br />
            <p>The contact information for our Designated Agent is:</p>
            <br />
            <p>XORBIT.AI</p>
            <br />
            <p>Attention: Copyright Agent</p>
            <br />
            <p>
              Los Verde, 101 Avenida Norte #517, San Salvador, El Salvador |
              Email: support@xorbit.ai
            </p>
            <br />
            <p>
              If you believe that any of your intellectual property rights other
              than copyrights have been infringed, please e-mail us at
              support@xorbit.ai. We reserve the right, in our sole and absolute
              discretion, to suspend or terminate any user who infringes the
              intellectual property rights of XORBIT.AI or others, and/or to
              remove, delete, edit or disable access to such person’s content.
              You agree that we have no liability for any action taken under
              this section.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              6. About Software in our Services
            </p>
            <br />
            <p>
              The Services may enable you to access software running on our (or
              our vendors’) servers (collectively, “Software”). You agree that
              we retain the ownership of all rights, title, and interest in and
              to the Software.
            </p>
            <br />
            <p>
              XORBIT.AI, gives you a personal, non-commercial, worldwide,
              royalty-free, non-assignable, and non-exclusive license to use the
              Software to access the Services. This license is for the sole
              purpose of enabling you to use and enjoy the benefit of the
              Services as provided by us, in the manner permitted by these
              Terms. You may not copy, modify, distribute, sell, or lease any
              part of our Services or Software, nor may you reverse engineer or
              attempt to extract the source code of the Services or Software,
              unless laws prohibit those restrictions or you have our written
              permission.
            </p>
            <br />
            <p>
              There may be software programs contained within certain Software
              that have been licensed to us by third parties. The term
              “Software” as used herein shall refer to this third-party software
              except where the term “Software” is used in the context of our
              ownership. The same terms and conditions, including all
              limitations and restrictions, set forth in these Terms apply to
              each third-party software program contained in the Software. You
              acknowledge and agree that any third-party components are owned by
              their applicable licensors. We do not make any representations or
              warranties about the operation or availability of such third-party
              software. Neither we, nor our licensors, shall be liable for any
              unavailability or removal of such third-party software. We are not
              responsible for any communications to or from such licensors, or
              for the collection or use of information by such licensors. You
              consent to the communications enabled and/or performed by such
              third-party software, including automatic updating of the
              third-party software without further notice. You agree that such
              third-party software licensors are intended thirdparty
              beneficiaries under these Terms.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              7. Modifying and Terminating our Services{" "}
            </p>
            <br />
            <p>
              We are constantly changing and improving our Services. We may add
              or remove functionalities or features, and we may suspend or stop
              a Service altogether, at any time, without any notice or
              liability.
            </p>
            <br />
            <p>
              You can stop using our Services at any time, although we’ll be
              sorry to see you go. We may also stop providing Services to you,
              or add or create new limits to our Services, at any time.
            </p>
            <br />
            <p>
              Sections 8 – 13 will survive termination or expiration of these
              Terms indefinitely.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              8. Our Warranties and Disclaimers{" "}
            </p>
            <br />
            <p>
              OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS, NEITHER XORBIT.AI
              NOR ITS LICENSORS, SUPPLIERS, ADVERTISERS, OR DISTRIBUTORS MAKE
              ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON’T
              MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE
              SPECIFIC FUNCTIONS OF THE SERVICES, OR THEIR RELIABILITY,
              AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE ALSO DO NOT MAKE
              ANY WARRANTIES OR COMMITMENT RELATING TO NON-INFRINGEMENT, FREEDOM
              FROM VIRUSES OR OTHER HARMFUL CODE, OR ERRORFREE OR UNINTERRUPTED
              OPERATIONS. WE PROVIDE THE SERVICES “AS-IS.”
            </p>
            <br />
            <p>
              SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE
              IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
              PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE
              DISCLAIM ALL WARRANTIES.
            </p>
            <br />
            <p>
              YOU AND YOUR HEIRS, SUCCESSORS, AND ASSIGNS HEREBY FOREVER
              IRREVOCABLY RELEASE, DISCHARGE, AND HOLD HARMLESS US, OUR
              AFFILIATES, AND OUR AND THEIR SUCCESSORS AND ASSIGNS, AND ITS AND
              THEIR OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS (COLLECTIVELY,
              “RELEASED PARTIES”) FROM, AND AGREE NOT TO SUE ANY RELEASED PARTY
              FOR, ANY LIABILITIES, CLAIMS, OBLIGATIONS, SUITS, ACTIONS,
              DEMANDS, EXPENSES, AND DAMAGES WHATSOEVER (COLLECTIVELY,
              “LIABILITIES”) THAT YOU MAY HAVE AGAINST ANY RELEASED PARTY
              WHETHER EXISTING NOW OR IN THE FUTURE, WHETHER KNOWN OR UNKNOWN,
              ARISING OUT OF OR IN CONNECTION WITH YOUR OR A THIRD PARTY’S
              CONDUCT RELATED TO USE OF THE SERVICES. YOU UNDERSTAND AND
              ACKNOWLEDGE THAT THE FOREGOING SENTENCE RELEASES AND DISCHARGES
              ALL LIABILITIES, WHETHER OR NOT THEY ARE CURRENTLY KNOWN TO YOU,
              AND YOU WAIVE YOUR RIGHTS UNDER EL SALAVADOR CIVIL CODE SECTION
              1542. YOU UNDERSTAND THE MEANING OF EL SALVADOR CIVIL CODE SECTION
              1542, WHICH READS AS FOLLOWS: “A GENERAL RELEASE DOES NOT EXTEND
              TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN
              HIS FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY
              HIM MUST HAVE MATERIALLY AFFECTED HIS SETTLEMENT WITH THE DEBTOR.”
              BY AGREEING TO THESE TERMS AND THIS WAIVER, YOU ASSUME ALL RISK
              ARISING FROM YET UNKNOWN CLAIMS.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>9. Liability for our Services</p>
            <br />
            <p>
              TO THE EXTENT NOT PROHIBITED BY LAW, XORBIT.AI (AND ITS OFFICERS,
              DIRECTORS, EMPLOYEES, AND AGENTS) AND OUR LICENSORS, SUPPLIERS,
              ADVERTISERS, AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST
              PROFITS, REVENUES, OR DATA, Crypto LOSSES OR INDIRECT, SPECIAL,
              CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES. TO THE EXTENT NOT
              PROHIBITED BY LAW, THE TOTAL LIABILITY OF XORBIT.AI (AND ITS
              OFFICERS, DIRECTORS, EMPLOYEES, AND AGENTS) AND OUR LICENSORS,
              SUPPLIERS, ADVERTISERS, AND DISTRIBUTORS, FOR ANY AND ALL CLAIMS
              UNDER THESE TERMS OR RELATING TO YOUR USE OF THE SERVICES,
              INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU
              PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLY YOU THE
              SERVICES AGAIN). IN ALL CASES RELATING TO PROVIDING, YOU THE
              SERVICES, XORBIT.AI, (AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AND
              AGENTS) AND ITS LICENSORS, SUPPLIERS, ADVERTISERS, AND
              DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS
              NOT REASONABLY FORESEEABLE OR THAT IS DUE TO EVENTS OUTSIDE OF OUR
              REASONABLE CONTROL, SUCH AS WARS, CRIMINAL ACTIVITIES, STORMS,
              NATURAL DISASTERS, ACTS OF GOVERNMENT, SUPPLY INTERRUPTIONS, OR
              TELECOMMUNICATION OR INTERNET FAILURES.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>
              10. Business/Employer Uses of our Services
            </p>
            <br />
            <p>
              If you are using our Services on behalf of a business or employer,
              you are accepting these Terms on their behalf, and that business
              or employer agrees to be bound by these Terms.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>11. Indemnification </p>
            <br />
            <p>
              You hereby agree to indemnify, defend, and hold harmless
              XORBIT.AI, its affiliated companies, and its and their
              predecessors, successors, and assigns, and its and their
              respective directors, officers, employees, agents,
              representatives, partners, and contractors from and against all
              claims, losses, expenses, damages and costs (including, but not
              limited to, reasonable attorneys’ fees), resulting from or arising
              out of your actual or alleged breach of these Terms, any content
              you provide through the Services, or your use or misuse of the
              Services. However, you will not be responsible for claims,
              damages, and costs which are found by a court of competent
              jurisdiction to have arisen solely from our violation of
              applicable law.
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>12. About these Terms </p>
            <br />
            <p>
              We may modify these Terms or any additional terms that apply to a
              Service for any reason, for example, to reflect changes to the law
              or changes to our Services. You should look at the Terms regularly
              and the “Last Updated” date at the beginning of these Terms. We’ll
              use reasonable efforts to give you notice of these modifications,
              such as posting notice of modifications to these Terms on this web
              page, through the Services, or via email. By continuing to use the
              Services after we make these modifications, you agree that you
              will be subject to the modified Terms. If you do not agree to the
              modified terms for a Service, you should discontinue your use of
              that Service
            </p>
            <br />
            <p>
              If there is a conflict between these Terms and any additional
              terms for a Service, the additional terms will control for that
              conflict.
            </p>
            <br />
            <p>
              These Terms control the relationship between XORBIT.AI and you.
              They do not create any third-party beneficiary rights (except as
              set forth in Section 6). If you do not comply with these Terms,
              and we don’t take action right away, this doesn’t mean that we are
              giving up any rights that we may have (such as taking action in
              the future). If it turns out that a particular term is not
              enforceable, this will not affect any other terms.
            </p>
            <br />
            <p>
              The laws of the United States and the State of El Salvador,
              excluding El Salvador’s conflict of laws rules, will apply to any
              disputes arising out of or relating to these terms or the
              Services.
            </p>
            <br />
            <p>
              You may not assign or delegate your rights or obligations relating
              to these terms or your account for the Services without our prior
              written consent. We may assign these terms or assign or delegate
              any of our rights or obligations at any time.
            </p>
            <br />
            <p>
              For information about how to contact XORBIT.AI, please visit our
              <a
                target="_blank"
                href="https://www.banks.com/"
                style={{ color: "green" }}
              >
                contact page
              </a>{" "}
              .
            </p>
            <br />
            <p style={{ fontWeight: "bold" }}>13. Binding Arbitration </p>
            <br />
            <p>
              Without limiting your waiver and release in Section 8, you agree
              to the following:
            </p>
            <br />
            <p>
              <ul style={{ padding: 20 }}>
                <li style={{ listStyle: "decimal" }}>
                  <span style={{ fontWeight: "bold" }}>Purpose.</span> Any and
                  all Disputes (as defined below) involving you and XORBIT.AI
                  will be resolved through individual arbitration. In
                  arbitration, there is no judge or jury and there is less
                  discovery and appellate review than in court. This Section 13
                  (the “Arbitration Provision”) shall be broadly interpreted.
                  Notwithstanding anything to the contrary in these Terms, this
                  Section 13 does not apply to an action by either party to
                  enjoin the infringement or misuse of its intellectual property
                  rights, including copyright, trademark, patent or trade secret
                  rights
                </li>
                <br />
                <li style={{ listStyle: "decimal" }}>
                  <span style={{ fontWeight: "bold" }}>Definitions.</span> The
                  term “Dispute” means any claim or controversy related to the
                  Services or the Software, including but not limited to any and
                  all: (1) claims for relief and theories of liability, whether
                  based in contract, tort, fraud, negligence, statute,
                  regulation, ordinance, or otherwise; (2) claims that arose
                  before these Terms or any prior agreement; (3) claims that
                  arise after the expiration or termination of these Terms; and
                  (4) claims that are currently the subject of purported class
                  action litigation in which you are not a member of a certified
                  class. As used in this Arbitration Provision, “XORBIT.AI”
                  means XORBIT.AI and any of its predecessors, successors,
                  assigns, parents, subsidiaries and affiliated companies and
                  each of their respective officers, directors, employees and
                  agents, and “you” means you and any users or beneficiaries of
                  your access to the Services or the Software.
                </li>
                <p>
                  <br />
                  Initiation of Arbitration Proceeding/Selection of Arbitrator.
                  The party initiating the arbitration proceeding may open a
                  case with JAMS, formerly Judicial Arbitration and Mediation
                  Services, Inc., (“JAMS”). You may deliver any required or
                  desired notice to XORBIT.AI by mail to Los Verde, 101 Avenida
                  Norte #517, San Salvador, El Salvador
                </p>
                <br />
                <li style={{ listStyle: "decimal" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Right to Sue in Small Claims Court.
                  </span>{" "}
                  Notwithstanding anything in this Arbitration Provision to the
                  contrary, either you or XORBIT.AI may bring an individual
                  action in a small claims court in the area where you access
                  the Services if the claim is not aggregated with the claim of
                  any other person and if the amount in controversy is properly
                  within the jurisdiction of the small claims court.
                </li>
                <br />
                <li style={{ listStyle: "decimal" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Arbitration Procedures.
                  </span>{" "}
                  This Arbitration Provision shall be governed by the Federal
                  Arbitration Act. Arbitrations shall be administered by JAMS
                  pursuant to its Streamlined Arbitration Rules and Procedures
                  (the “JAMS Rules”) as modified by the version of this
                  Arbitration Provision that is in effect when you notify
                  XORBIT.AI about your Dispute. You can obtain the JAMS Rules
                  from the JAMS. If there is a conflict between this Arbitration
                  Provision and the rest of these Terms, this Arbitration
                  Provision shall govern. If there is a conflict between this
                  Arbitration Provision and the JAMS rules, this Arbitration
                  Provision shall govern. If JAMS will not administer a
                  proceeding under this Arbitration Provision as written, the
                  parties shall agree on a substitute arbitration organization.
                  If the parties cannot agree, the parties shall mutually
                  petition a court of appropriate jurisdiction to appoint an
                  arbitration organization that will administer a proceeding
                  under this Arbitration Provision as written applying the JAMS
                  Rules. A single arbitrator will resolve the Dispute. Unless
                  you and XORBIT.AI agree otherwise, any arbitration hearing
                  will take place in, EL SALVADOR. The arbitrator will honor
                  claims of privilege recognized by law and will take reasonable
                  steps to protect customer account information and other
                  confidential or proprietary information. The arbitrator shall
                  issue a reasoned written decision that explains the
                  arbitrator’s essential findings and conclusions. The
                  arbitrator’s award may be entered in any court having
                  jurisdiction over the parties only if necessary for purposes
                  of enforcing the arbitrator’s award. An arbitrator’s award
                  that has been fully satisfied shall not be entered in any
                  court.
                </li>
                <br />
                <li style={{ listStyle: "decimal" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Waiver of Class Actions and Collective Relief
                  </span>
                  . THERE SHALL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE
                  ARBITRATED OR LITIGATED ON A CLASS ACTION, JOINT OR
                  CONSOLIDATED BASIS OR ON BASES INVOLVING CLAIMS BROUGHT IN A
                  PURPORTED REPRESENTATIVE CAPACITY ON BEHALF OF THE GENERAL
                  PUBLIC (SUCH AS A PRIVATE ATTORNEY GENERAL), OTHER
                  SUBSCRIBERS, OR OTHER PERSONS. THE ARBITRATOR MAY AWARD RELIEF
                  ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING RELIEF AND ONLY
                  TO THE EXTENT NECESSARY TO PROVIDE RELIEF WARRANTED BY THAT
                  INDIVIDUAL PARTY’S CLAIM. THE ARBITRATOR MAY NOT CONSOLIDATE
                  MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT OTHERWISE PRESIDE
                  OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING
                </li>
                <br />
                <li style={{ listStyle: "decimal" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Arbitration Fees and Costs
                  </span>{" "}
                  . If your claim seeks more than $75,000 in the aggregate, the
                  payment of the JAMS’s fees and costs will be governed by the
                  JAMS Rules. If your claims seek less than $75,000 in the
                  aggregate, the payment of the JAMS’s fees and costs will be
                  XORBIT.AI, Inc’s responsibility. However, if the arbitrator
                  finds that your Dispute was frivolous or brought for an
                  improper purpose (as measured by the standards set forth in
                  Federal Rule of Civil Procedure 11(b)), the payment of the
                  JAMS’s fees and costs shall be governed by the JAMS Rules and
                  you shall reimburse XORBIT.AI for all fees and costs that were
                  your obligation to pay under the JAMS Rules. You may hire an
                  attorney to represent you in arbitration. You are responsible
                  for your attorneys’ fees and additional costs and may only
                  recover your attorneys’ fees and costs in the arbitration to
                  the extent that you could in court if the arbitration is
                  decided in your favor. Notwithstanding anything in this
                  Arbitration Provision to the contrary, XORBIT.AI will pay all
                  fees and costs that it is required by law to pay
                </li>
                <br />
                <li style={{ listStyle: "decimal" }}>
                  <span style={{ fontWeight: "bold" }}>
                    {" "}
                    Severability and Waiver of Jury Trial.
                  </span>{" "}
                  If any part of subsection (f) of this Arbitration Provision is
                  found to be illegal or unenforceable, the entire Arbitration
                  provision will be unenforceable and the Dispute will be
                  decided by a court. WHETHER IN COURT OR IN ARBITRATION, YOU
                  AND XORBIT.AI, AGREE TO WAIVE THE RIGHT TO A TRIAL BY JURY TO
                  THE FULLEST EXTENT ALLOWED BY LAW. If any other clause in this
                  Arbitration Provision is found to be illegal or unenforceable,
                  that clause will be severed from this Arbitration Provision
                  and the remainder of this Arbitration Provision will be given
                  full force and effect.
                </li>
                <br />
                <li style={{ listStyle: "decimal" }}>
                  <span style={{ fontWeight: "bold" }}>Continuation.</span> This
                  Arbitration Provision will survive the termination or
                  expiration of these Terms.
                </li>
              </ul>
            </p>
          </div>
        </DialogContent>
      </Dialog>{" "}
    </div>
  );
};

export default TermsOfService;
